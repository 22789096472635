<template>
  <div class="relative w-full min-h-screen overflow-x-clip">
    <Sidebar
      :is-collapsed="isSidebarCollapsed"
      @toggle-sidebar="isSidebarCollapsed = !isSidebarCollapsed"
    />
    <main
      class="relative w-full min-h-screen"
      :class="isSidebarCollapsed ? 'sm:pl-24' : 'sm:pl-56'"
    >
      <Navbar
        :title="title"
        :tabs="tabs"
        :is-collapsed="isSidebarCollapsed"
        @toggle-chatbot="showChatbot = !showChatbot"
        @toggle-sidebar="isSidebarCollapsed = !isSidebarCollapsed"
      />
      <div class="flex items-start flex-1 w-full h-full">
        <div class="flex-1 w-full">
          <slot/>
        </div>
        <div class="fixed right-0 w-full p-2 max-w-80 xl:sticky top-16 z-30">
          <Transition name="fade">
            <ChatBot v-if="showChatbot" />
          </Transition>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
const showChatbot = ref(false);

defineProps<{
  tabs: { title: string; link: string }[];
  title: string;
}>();

const isSidebarCollapsed = ref(true);
onMounted(() => {
  isSidebarCollapsed.value = window.innerWidth < 768;
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
